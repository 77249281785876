import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/User/AuthService";
import ProfileService from "../../../services/User/ProfileService";
import DbModuleService from "../../../services/User/DbModuleService";
import NoticeService from "../../../services/User/NoticeService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import NoticeDialog from "./NoticeDialog";

import "./style.css";

const Dashboard = () => {
  const loadingCtx = useContext(LoadingContext);

  const [wallet, setWallet] = useState();
  const [data, setData] = useState(false);

  const [sessionUserType, setSessionUserType] = useState();
  const [name, setName] = useState();
  const [userId, setUserId] = useState();
  const [mobile, setMobile] = useState();

  useEffect(() => {
    getBalance();
    getUserType();
    fetchAllNotice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserType = () => {
    const _userType = AuthService.getUserType();
    const _name = AuthService.getName();
    const _userId = AuthService.getUserID();
    const _mobile = AuthService.getMobile();

    setName(_name);
    setUserId(_userId);
    setMobile(_mobile);
    setSessionUserType(_userType);
  };

  const getBalance = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.getBalance(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        if (message == "Unauthorized Access") {
          AuthService.logout();
          window.location.href = "/login";
        }
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const autoLoginHandler = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await DbModuleService.adminLogin(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const accessToken = responseData.data.access_token;
        const name = responseData.data.name;
        const user_id = responseData.data.user_id;
        const user_type = responseData.data.user_type;

        localStorage.setItem("admin_timestamp", new Date().getTime());
        localStorage.setItem("admin_access_token", accessToken);
        localStorage.setItem(
          "admin",
          JSON.stringify({
            name: name,
            user_type: user_type,
            user_id: user_id,
          })
        );

        window.open("/admin/dashboard", "_blank", "");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  function percentage(numVal1, numVal2) {
    let percentage = (numVal1 / numVal2) * 100;
    return percentage.toFixed(2);
  }

  const [open, setOpen] = useState(false);
  const [noticeData, setNoticeData] = useState([]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const fetchAllNotice = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await NoticeService.fetchAll(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const data = responseData.data;
        setNoticeData(responseData.data);

        if (data.length > 0) {
          setOpen(true);
        }
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Dashboard">
        <section class="content">
          <div class="container-fluid">
            <div className="card">
              {/* <img src="/assets/img/trading.gif" alt="trading-img" /> */}
            </div>

            <div className="row">
              <div class="col-xl-12">
                <div class="card cstm-bg">
                  <div class="card-body cstm-bg-dark">
                    <div class="buy-coin">
                      <div class="row">
                        <div class="col" style={{ color: "#ffc822" }}>
                          <h3>Welcome! {name}</h3>
                          <h5>User ID : {userId}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {sessionUserType === "Employee" && (
              <div className="row">
                <div className="col-md-6">
                  <div class="alert alert-primary">
                    <span>
                      <i class="icon fas fa-info"></i> Alert! You are logged in
                      with access to admin control.{" "}
                    </span>
                    <span
                      style={{
                        float: "right",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      <a onClick={autoLoginHandler}>Go to Admin Panel</a>
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* {data && (
              <div className="row">
                <div className="col-md-6">
                  <div class="alert alert-info">
                    <span>
                      <i class="icon fas fa-info"></i> Alert! You have completed
                      Monthly Business Target of{" "}
                      <strong style={{ color: "white", fontSize: "18px" }}>
                        {percentage(
                          data.team_invested_amount_current_month
                            ? data.team_invested_amount_current_month
                            : "0",
                          data.mbt_amount ? data.mbt_amount : "0"
                        )}
                        %
                      </strong>
                    </span>
                  </div>
                </div>
              </div>
            )} */}

            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>₹{data.wallet ? data.wallet.main_wallet : ""}</h3>
                    <p>Available Investment Balance</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a href="#" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>
                      <h3>
                        ₹{data.wallet ? data.wallet.commission_wallet : ""}
                      </h3>
                    </h3>
                    <p>Available Commission Balance</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a href="/user/commission_history" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>₹{data.total_income ? data.total_income : "0"}</h3>
                    <p>Total Income Since Begining</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a href="/user/income_summary" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>
                      ₹
                      {data.previous_month_income
                        ? data.previous_month_income
                        : "0"}
                    </h3>
                    <p>Previous Month Income</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a href="/user/income_summary" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>₹{data.wallet ? data.wallet.main_wallet : ""}</h3>
                    <p>Self Invested Amount</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a href="/user/investment_history" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>
                      {" "}
                      ₹
                      {data.team_invested_amount
                        ? data.team_invested_amount
                        : ""}
                    </h3>
                    <p>Downline Invested Amount</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <a
                    href="/user/team_investment_history"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>
                      {data.journey_in_days ? data.journey_in_days : "NULL"}
                    </h3>
                    <p>Journey With Nexxture (days)</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a href="/user/my_profile" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>₹{data.wallet ? data.wallet.credit_wallet : ""}</h3>
                    <p>Total Credit Taken</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <a href="#" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>₹{data.mbt_amount ? data.mbt_amount : ""}</h3>
                    <p>Monthly Business Target</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a
                    href="/user/team_investment_history"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>
                      {" "}
                      ₹
                      {data.team_invested_amount_current_month
                        ? data.team_invested_amount_current_month
                        : ""}
                    </h3>
                    <p>
                      Monthly Business Target Achieved (
                      {percentage(
                        data.team_invested_amount_current_month
                          ? data.team_invested_amount_current_month
                          : "0",
                        data.mbt_amount ? data.mbt_amount : "0"
                      )}
                      %)
                    </p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <a
                    href="/user/team_investment_history"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>₹{data.bdc_amount ? data.bdc_amount : "0"}</h3>
                    <p>Business Development Commission</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a href="/user/my_profile" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>

      <NoticeDialog
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        noticeData={noticeData}
      />
    </React.Fragment>
  );
};

export default Dashboard;

import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";
import { states } from "../../../utill/Common";

const EditInvestmentForm = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formRef = useRef();
  const paymentDateRef = useRef();
  const adminRemarkRef = useRef();
  const isClosedRef = useRef();

  const chequeStatusRef = useRef();
  const chequeDetailsRef = useRef();
  const chequeReturnDateRef = useRef();

  const enableDisburseCommissionRef = useRef();
  const enableDisburseUplineCommissionRef = useRef();

  const updateHandler = async () => {
    const paymentDate = paymentDateRef.current.value;
    const adminRemark = adminRemarkRef.current.value;
    const isClosed = isClosedRef.current.value;

    const chequeStatus = chequeStatusRef.current.value;
    const chequeDetails = chequeDetailsRef.current.value;
    const chequeReturnDate = chequeReturnDateRef.current.value;

    const enableDisburseCommission = enableDisburseCommissionRef.current.value;
    const enableDisburseUplineCommission =
      enableDisburseUplineCommissionRef.current.value;

    if (!paymentDate) {
      Swal.fire("Opps!", "Invalid Payment Date", "error");
      return;
    }

    // if (!adminRemark) {
    //   Swal.fire("Opps!", "Invalid Remark", "error");
    //   return;
    // }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await InvestmentService.updateInvestment(
        token,
        id,
        paymentDate,
        adminRemark,
        isClosed,
        chequeStatus,
        chequeDetails,
        chequeReturnDate,
        enableDisburseCommission,
        enableDisburseUplineCommission
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.fetchByInvestmentId(
        token,
        id
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div class="row">
        <div class="col-md-6 mx-auto">
          {data && (
            <form ref={formRef} autoComplete="off">
              <div class="card-body">
                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Payment Date</label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      ref={paymentDateRef}
                      defaultValue={data ? data.payment_date : ""}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">IS Closed</label>
                  <div class="col-sm-9">
                    <select className="form-control" ref={isClosedRef}>
                      <option value={data.is_closed}>{data.is_closed}</option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Admin Remark</label>
                  <div class="col-sm-9">
                    <textarea
                      ref={adminRemarkRef}
                      className="form-control"
                      placeholder="Remark"
                      rows="3"
                      defaultValue={data ? data.admin_remark : ""}
                    ></textarea>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Cheque Status</label>
                  <div class="col-sm-9">
                    <select className="form-control" ref={chequeStatusRef}>
                      <option value={data.cheque_status}>
                        {data.cheque_status}
                      </option>
                      <option value="Not Applicable">Not Applicable</option>
                      <option value="Delivered">Delivered</option>
                      <option value="Returned">Returned</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">Cheque Details</label>
                  <div class="col-sm-9">
                    <textarea
                      ref={chequeDetailsRef}
                      className="form-control"
                      placeholder="Cheque Details"
                      rows="3"
                      defaultValue={data ? data.cheque_details : ""}
                    ></textarea>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">
                    Cheque Return Date
                  </label>
                  <div class="col-sm-9">
                    <input
                      type="text"
                      class="form-control"
                      ref={chequeReturnDateRef}
                      defaultValue={data ? data.cheque_return_date : ""}
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">
                    Enable Disburse Commission
                  </label>
                  <div class="col-sm-9">
                    <select
                      className="form-control"
                      ref={enableDisburseCommissionRef}
                    >
                      <option value={data.enable_disburse_commission}>
                        {data.enable_disburse_commission}
                      </option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                      <option value=""></option>
                    </select>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-sm-3 col-form-label">
                    Enable Disburse Upline Commission
                  </label>
                  <div class="col-sm-9">
                    <select
                      className="form-control"
                      ref={enableDisburseUplineCommissionRef}
                    >
                      <option value={data.enable_disburse_upline_commission}>
                        {data.enable_disburse_upline_commission}
                      </option>
                      <option value="YES">YES</option>
                      <option value="NO">NO</option>
                      <option value=""></option>
                    </select>
                  </div>
                </div>

                <div class="card-footer text-center">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={updateHandler}
                  >
                    Submit
                  </button>
                  <button type="reset" class="btn btn-default ml-5">
                    Reset
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditInvestmentForm;

import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import { ToWords } from "to-words";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

import "./ReceiptStyle.css";

const InvestmentReceipt = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState();
  const [userData, setUserData] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.id ? props.id : props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.fetchByInvestmentId(
        token,
        id
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        //alert(JSON.stringify(responseData.data));
        setData(responseData.data);
        fetchUserData(responseData.data.user_id);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const fetchUserData = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.fetchByUserId(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        //  alert(JSON.stringify(responseData.data.result));
        setUserData(responseData.data.result);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const handleUpdateLastPrintDate = async (id) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const id = props.id ? props.id : props.match.params.id;

      const responseData = await InvestmentService.updateLastPrintDate(
        token,
        id
      );

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        Swal.fire("Success!", message, "success");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  return (
    <React.Fragment>
      <div className="centerOnPrintedPage">
        {/* <Templete t1="Home" t2="View Agent">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">View Agent</h3>
                  </div> */}

        {/* <div class="row">
        <div class="col-md-6 mx-auto"> */}
        {data && userData && (
          <table cellpadding="0" cellspacing="0" border="0" width="100%">
            <tr>
              <td>
                <table
                  cellSpacing="0"
                  cellPadding="0"
                  width="100%"
                  align="center"
                >
                  <tbody>
                    <tr>
                      <td>
                        <table
                          cellpadding="3"
                          cellspacing="0"
                          border="0"
                          width="100%"
                          align="center"
                        >
                          <tr>
                            <td width="10%" align="center" valign="middle">
                              <br />
                              <br />
                              <table
                                cellpadding="4"
                                cellspacing="0"
                                border="1"
                                width="70%"
                                align="center"
                              >
                                <tr>
                                  <td colspan="2">
                                    <table border="0">
                                      <tr>
                                        <td width="610" align="center">
                                          <font color="#000000" size="+1">
                                            <b>Investment Receipt</b>
                                          </font>
                                        </td>
                                      </tr>
                                    </table>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="4">
                                    <font size="+1">
                                      <b>Investment Details:</b>
                                    </font>
                                  </td>
                                </tr>
                                <tr>
                                  <td width="44%">
                                    <b>Branch Name</b>
                                  </td>
                                  <td width="56%">SAJIRHAT, MADHYAMGRAM</td>
                                </tr>
                                <tr>
                                  <td width="44%">
                                    <b>Branch Address</b>
                                  </td>
                                  <td width="56%">
                                    SAJIRHAT, MADHYAMGRAM, KOLKATA 700130
                                  </td>
                                </tr>
                                <tr>
                                  <td width="44%">
                                    <b>Investor Name</b>
                                  </td>
                                  <td width="56%">{userData.name}</td>
                                </tr>
                                <tr>
                                  <td width="44%">
                                    <b>Address</b>
                                  </td>
                                  <td width="56%">{userData.address}</td>
                                </tr>
                                <tr>
                                  <td width="44%">
                                    <b>Order ID</b>
                                  </td>
                                  <td width="56%">{data.order_id}</td>
                                </tr>
                                <tr>
                                  <td width="44%">
                                    <b>Investment Amount</b>
                                  </td>
                                  <td
                                    width="56%"
                                    style={{
                                      fontWeight: "bold",
                                      color: "red",
                                    }}
                                  >
                                    {data.amount} [
                                    {toWords.convert(data.amount)}]
                                  </td>
                                </tr>
                                <tr>
                                  <td width="44%">
                                    <b>Investment Date</b>
                                  </td>
                                  <td width="56%">{data.payment_date}</td>
                                </tr>

                                {userData.nominee_name && (
                                  <React.Fragment>
                                    <tr>
                                      <td colspan="4">
                                        <font size="+1">
                                          <b>Nominee Details:</b>
                                        </font>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Nominee Name</b>
                                      </td>
                                      <td>{userData.nominee_name}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Nominee Relation</b>
                                      </td>
                                      <td>{userData.nominee_relation}</td>
                                    </tr>
                                  </React.Fragment>
                                )}

                                {userData.beneficiary_name && (
                                  <React.Fragment>
                                    <tr>
                                      <td colspan="4">
                                        <font size="+1">
                                          <b>Bank A/C Details:</b>
                                        </font>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Beneficiary Name</b>
                                      </td>
                                      <td>{userData.beneficiary_name}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Bank Name</b>
                                      </td>
                                      <td>{userData.bank_name}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Account Number</b>
                                      </td>
                                      <td>{userData.account_number}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>IFSC Code</b>
                                      </td>
                                      <td>{userData.ifsc_code}</td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <b>Account Type</b>
                                      </td>
                                      <td>{userData.account_type}</td>
                                    </tr>
                                  </React.Fragment>
                                )}

                                <tr>
                                  <td>
                                    <b>Last Print Date</b>
                                  </td>
                                  <td>{data.last_receipt_print_on}</td>
                                </tr>
                              </table>

                              <div class="card-footer text-center">
                                <button
                                  type="button"
                                  class="btn btn-primary"
                                  onClick={() => {
                                    handleUpdateLastPrintDate();
                                    window.print();
                                  }}
                                >
                                  Print
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-default ml-5"
                                  onClick={() => {
                                    // history.push("/agent_list");
                                    window.close();
                                  }}
                                >
                                  Close
                                </button>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </table>
        )}
        {/* </div>
      </div> */}
        {/* </div>
              </div>
            </div>
          </div>
        </section>
      </Templete> */}
      </div>
    </React.Fragment>
  );
};

export default InvestmentReceipt;

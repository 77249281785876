import React, { useState, useEffect, useContext } from "react";
import Swal from "sweetalert2";
import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import ProfileService from "../../../services/Admin/ProfileService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const Dashboard = () => {
  const loadingCtx = useContext(LoadingContext);

  const [data, setData] = useState(false);

  useEffect(() => {
    getBalance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBalance = async () => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await ProfileService.getBalance(token);
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        setData(responseData.data);
      } else {
        if (message === "Unauthorized Access") {
          AuthService.logout();
          window.location.href = "/admin_login";
        }
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Dashboard">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>
                      {data.today_joining_count ? data.today_joining_count : ""}
                    </h3>
                    <p>Today Joining</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a href="/admin/user_list" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>
                      {data.total_users_count ? data.total_users_count : ""}
                    </h3>
                    <p>Total Users</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a href="/admin/user_list" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>
                      ₹
                      {data.today_investment_amount
                        ? data.today_investment_amount
                        : ""}{" "}
                    </h3>
                    <p>Today Investment Amount</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a href="/admin/investment_history" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-danger">
                  <div class="inner">
                    <h3>
                      ₹
                      {data.total_investment_amount
                        ? data.total_investment_amount
                        : ""}{" "}
                    </h3>
                    <p>Total Investment Amount</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-pie-graph"></i>
                  </div>
                  <a href="/admin/investment_history" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-3 col-6">
                <div class="small-box bg-info">
                  <div class="inner">
                    <h3>
                      ₹{data.today_withdrawal ? data.today_withdrawal : ""}
                    </h3>
                    <p>Today Withdrawal Amount</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-bag"></i>
                  </div>
                  <a href="/admin/withdrawal_history" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-success">
                  <div class="inner">
                    <h3>
                      ₹
                      {data.total_withdrawal_amount
                        ? data.total_withdrawal_amount
                        : ""}
                    </h3>
                    <p>Total Withdrawal Amount</p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-stats-bars"></i>
                  </div>
                  <a href="/admin/withdrawal_history" class="small-box-footer">
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>

              <div class="col-lg-3 col-6">
                <div class="small-box bg-warning">
                  <div class="inner">
                    <h3>
                      ₹
                      {data.today_withdrawal_pending
                        ? data.today_withdrawal_pending
                        : ""}{" "}
                    </h3>
                    <p>
                      Total Pending Withdrawal Amount (
                      {data.today_pending_withdrawal_count
                        ? data.today_pending_withdrawal_count
                        : ""}
                      )
                    </p>
                  </div>
                  <div class="icon">
                    <i class="ion ion-person-add"></i>
                  </div>
                  <a
                    href="/admin/pending_withdrawal_request"
                    class="small-box-footer"
                  >
                    More info <i class="fas fa-arrow-circle-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default Dashboard;

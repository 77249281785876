import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import DataTableComponent from "react-data-table-component";
import DownloadIcon from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import InvestmentService from "../../../services/Admin/InvestmentService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const ActiveInvestments = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalAmountWithdrawn, setTotalAmountWithdrawn] = useState();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    loadingCtx.startLoading();

    const id = props.match.params.id;

    try {
      const token = AuthService.getAccessToken();

      const responseData = await AgentsService.activeInvestments(token, id);

      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const _data = responseData.data;
        setData(_data);

        let _totalAmount = 0;
        let _totalAmountWithdrawn = 0;

        _data.forEach((e) => {
          _totalAmount += e.amount;
          _totalAmountWithdrawn += e.amount_withdrawn;
        });

        setTotalAmount(_totalAmount);
        setTotalAmountWithdrawn(_totalAmountWithdrawn);
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  const columns = [
    {
      name: "#",
      cell: (row, index) => index + 1,
    },
    {
      name: "Order ID",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.order_id}</span>
        </div>
      ),
    },
    {
      name: "Amount",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ color: "blue", fontSize: "16px", fontWeight: "bold" }}>
            ₹{row.amount}
          </span>
        </div>
      ),
    },
    {
      name: "Amount Withdrawn",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>
            ₹{row.amount_withdrawn}
          </span>
        </div>
      ),
    },
    {
      name: "Payment Mode",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_mode}</span>
        </div>
      ),
    },
    {
      name: "Payment Date",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.payment_date}</span>
        </div>
      ),
    },
    {
      name: "Cheque Status",
      cell: (row) => (
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          <span>{row.cheque_status}</span>
        </div>
      ),
    },
    {
      name: "Receipt",
      cell: (row) => (
        <div>
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              //  downloadReceipt(row.order_id);

              window.open(
                "/admin/investment_receipt/" + row.id,
                "",
                "width=740,height=650,scrollbars=yes,resizable=1"
              );
              return true;
            }}
          >
            <DownloadIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const downloadReceipt = async (orderId) => {
    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const responseData = await InvestmentService.downloadReceipt(
        token,
        orderId
      );
      const status = responseData.status;
      const message = responseData.message;

      if (status === "SUCCESS") {
        const link = responseData.data.link;
        window.open(link, "_blank");
      } else {
        Swal.fire("Opps!", message, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <div className="container-fluid" style={{ padding: "20px" }}>
        <DataTableComponent
          noHeader={true}
          columns={columns}
          data={data}
          progressPending={loading}
          highlightOnHover
        />

        <br />
        {data && (
          <div>
            <p style={{ color: "blue", fontSize: "16px", fontWeight: "bold" }}>
              Total Invested Amount: ₹{totalAmount}
            </p>
            <p style={{ color: "red", fontSize: "16px", fontWeight: "bold" }}>
              Total Withdrawn Amount: ₹{totalAmountWithdrawn}
            </p>
            <p style={{ color: "green", fontSize: "18px", fontWeight: "bold" }}>
              Available Balance: ₹{totalAmount - totalAmountWithdrawn}
            </p>
          </div>
        )}

        <div class="card-footer text-center">
          <button
            type="button"
            class="btn btn-primary"
            onClick={() => {
              window.print();
            }}
          >
            Print
          </button>
          <button
            type="button"
            class="btn btn-default ml-5"
            onClick={() => {
              // history.push("/agent_list");
              window.close();
            }}
          >
            Close
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActiveInvestments;

import common from "../../utill/Common";

class AgentsService {
  fetchAgents = async (token, page, size, search_text, rank) => {
    const url = common.baseUrl + "admin/agents/fetch_agents";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ page, size, search_text, rank }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  add = async (
    token,
    parent_user_id,
    package_id,
    user_type,
    pan_number,
    title,
    name,
    dob,
    gender,
    fathers_name,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address,
    nominee_name,
    nominee_relation,
    beneficiary_name,
    bank_name,
    account_number,
    ifsc_code,
    account_type,
    joining_date
  ) => {
    const url = common.baseUrl + "admin/agents/add_agent";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        parent_user_id,
        package_id,
        user_type,
        pan_number,
        title,
        name,
        dob,
        gender,
        fathers_name,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
        nominee_name,
        nominee_relation,
        beneficiary_name,
        bank_name,
        account_number,
        ifsc_code,
        account_type,
        joining_date,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  update = async (
    token,
    user_id,
    user_type,
    parent_user_id,
    package_id,
    pan_number,
    rank,
    title,
    name,
    dob,
    gender,
    fathers_name,
    mobile,
    email,
    pincode,
    state,
    city,
    district,
    address,
    nominee_name,
    nominee_relation,
    status,
    joining_date,
    is_2fa,
    is_hide,
    mbt_amount,
    bdc_amount
  ) => {
    const url = common.baseUrl + "admin/agents/update_agent";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        user_id,
        user_type,
        parent_user_id,
        package_id,
        pan_number,
        rank,
        title,
        name,
        dob,
        gender,
        fathers_name,
        mobile,
        email,
        pincode,
        state,
        city,
        district,
        address,
        nominee_name,
        nominee_relation,
        status,
        joining_date,
        is_2fa,
        is_hide,
        mbt_amount,
        bdc_amount,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  updateBankDetails = async (
    token,
    user_id,
    beneficiary_name,
    bank_name,
    account_number,
    ifsc_code,
    account_type
  ) => {
    const url = common.baseUrl + "admin/agents/update_bank_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({
        token,
        user_id,
        beneficiary_name,
        bank_name,
        account_number,
        ifsc_code,
        account_type,
      }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  fetchByUserId = async (token, user_id) => {
    const url = common.baseUrl + "admin/agents/fetch_by_user_id";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ user_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  resetLoginDetails = async (token, user_id, password, pin) => {
    const url = common.baseUrl + "admin/agents/reset_login_details";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ user_id, password, pin }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  list = async (token) => {
    const url = common.baseUrl + "admin/agents/agent_list";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  userListTreeView = async (token) => {
    const url = common.baseUrl + "admin/agents/user_list_tree_view";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: null,
    };

    const response = await fetch(url, options);

    return response.json();
  };

  autoLogin = async (token, user_id) => {
    const url = common.baseUrl + "admin/agents/auto_login";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ user_id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  getLocation = async (token, pincode) => {
    const url = common.baseUrl + "admin/agents/get_location";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ pincode }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  deleteUser = async (token, id) => {
    const url = common.baseUrl + "admin/agents/delete_user";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  activeInvestments = async (token, id) => {
    const url = common.baseUrl + "admin/agents/active_investments";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id }),
    };

    const response = await fetch(url, options);

    return response.json();
  };

  sendWpMessage = async (token, id, message) => {
    const url = common.baseUrl + "admin/agents/send_wp_message";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify({ id, message }),
    };

    const response = await fetch(url, options);

    return response.json();
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new AgentsService();

import React, { useRef, useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";

import Templete from "../Templete/Templete";

import AuthService from "../../../services/Admin/AuthService";
import AgentsService from "../../../services/Admin/AgentsService";
import HttpError from "../../../utill/HttpError";
import LoadingContext from "../../../store/loading-context";

const SendWpMessage = (props) => {
  const loadingCtx = useContext(LoadingContext);

  const formRef = useRef();
  const messageRef = useRef();

  const submitHandler = async () => {
    const message = messageRef.current.value;

    if (!message) {
      Swal.fire("Opps!", "Invalid Message", "error");
      return;
    }

    loadingCtx.startLoading();

    try {
      const token = AuthService.getAccessToken();

      const userId = props.id ? props.id : props.match.params.id;

      const responseData = await AgentsService.sendWpMessage(
        token,
        userId,
        message
      );
      const status = responseData.status;
      const message1 = responseData.message;

      if (status === "SUCCESS") {
        // formRef.current.reset();
        Swal.fire("Success!", message1, "success");
      } else {
        Swal.fire("Opps!", message1, "error");
      }
    } catch (error) {
      const errMsg = HttpError.getError(error);
      Swal.fire("Opps!", errMsg, "error");
    }
    loadingCtx.stopLoading();
  };

  return (
    <React.Fragment>
      <Templete t1="Home" t2="Send Whatsapp Message">
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Send Whatsapp Message</h3>
                  </div>

                  <div class="row">
                    <div class="col-md-6 mx-auto">
                      <form ref={formRef}>
                        <div class="card-body">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">
                              Message
                            </label>
                            <div class="col-sm-10">
                              <textarea
                                type="text"
                                class="form-control"
                                placeholder="Message"
                                ref={messageRef}
                              />
                            </div>
                          </div>

                          <div class="card-footer text-center">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={submitHandler}
                            >
                              Submit
                            </button>
                            <button type="reset" class="btn btn-default ml-5">
                              Reset
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Templete>
    </React.Fragment>
  );
};

export default SendWpMessage;
